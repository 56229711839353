import { Link, Navigate, useParams } from "react-router-dom";
import { GuessAEmovi } from "../../../components/GuessAEmovi";
import { DAILY_EMOVI } from "../../../data/games";
import UserContext from "../../../UserProvider";
import { useContext } from "react";

export function ArchiveEmoviDateRoute() {
    const { date } = useParams<{ date: string }>();
    const { userDetails } = useContext(UserContext);
    if (!date || !userDetails?.subscription?.isPremium) {
        return <Navigate to="/archive" />;
    }
    const emoviToGuess = DAILY_EMOVI[date];
    if (!emoviToGuess) {
        return <Navigate to="/archive" />;
    }

    return <>
        <div
            className="flex flex-col items-center justify-center mt-2 mb-2 bg-slate-200 px-2 py-1 rounded-md shadow-md">
            <Link to={"/"} className="cursor-pointer text-blue-600 text-base">
                Return to today's game
            </Link>
        </div >
        <GuessAEmovi
            emoviToGuess={emoviToGuess}
            dailyNumber={12}
            dayString={date}
            isArchive={true}
        />
    </>
}